import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Impressum = () => (
  <Layout>
    <Seo title="Hochschulsportverband Niedersachsen/Bremen" />
    <section className="pt-10">

      <div className="container">
          <span className="badge badge-pill badge-primary-soft mb-3">
            <span className="h6 text-uppercase">Impressum</span>
          </span>
          <h2>Impressum</h2>
          <div className="row">
            <div className="col">
              <h5>Hochschulsportverband Niedersachsen/Bremen</h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                - Geschäftsstelle -<br />
                c/o Zentrum für Hochschulsport Hannover<br />
                Am Moritzwinkel 6<br />
                30167 Hannover<br />
                <a href="mailto:info@zfh.uni-hannover.de">info@zfh.uni-hannover.de</a>
              </p>
              
              <h5>
                Verantwortlich im Sinne des Presserechts gemäß § 55 Abs. 2 RStV:
              </h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Sebastian Knust
              </p>

              <h5>
                Redaktion & Screendesign
              </h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Peter Benyei<br />
                <a href="mailto:info@digital-smash.de">info@digital-smash.de</a>
                
              </p>
              
            </div>
            <div className="col">
              <h5>
                Der Datenschutzbeauftragte des Verantwortlichen ist:
              </h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Sebastian Knust<br />
                Zentrum für Hochschulsport Hannover<br />
                Am Moritzwinkel 6<br />
                30167 Hannover<br />
                Tel.: 0511 762 4964 <br />
                <a href="mailto:info@zfh.uni-hannover.de">knust@zfh.uni-hannover.de</a>
              </p>
            </div>
          </div>
          <p className="lead text-md-left text-muted mb-6 mb-lg-8">
            Die Internetseiten des HVNB dienen der Bereitstellung von Informationen für Mitglieder sowie an der Organisation interessierter Personen. Geschäftsmäßige Ziele und/oder eine kommerzielle Kommunikation werden nicht verfolgt.
          </p>
          <p className="lead text-md-left text-muted mb-6 mb-lg-8">
            Dieses Impressum gilt für die Informationsangebote, die über die URL www.hochschulsport-nord.de erreichbar sind.
          </p>
        </div>
        <div className="container">
          <h2>Disclaimer</h2>
            <div className="row">
              <div className="col">
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                  Die Texte und Fotos sind urheberrechtlich geschützt. Das Kopieren dieser Dateien und ihre evtl. Veränderung sind daher ohne Genehmigung des Rechteinhabers (soweit nicht anders gekennzeichnet, ist dies der HVNB) nicht gestattet. Unberührt davon bleibt das "Kopieren" der Dateien auf den eigenen Rechner, um sich die WWW-Seiten auf einem Browser anzuschauen.
                </p>
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                  Die Zusammenstellung der Informationen auf dieser Website wurde vom HVNB mit größtmöglicher Sorgfalt vorgenommen. Dennoch kann keinerlei Gewähr für Aktualität, Richtigkeit, Vollständigkeit und Qualität der bereitgestellten Daten übernommen werden. Haftungsansprüche gegen den HVNB oder die Autoren bzw. Verantwortlichen dieser Website für Schäden materieller oder immaterieller Art, die auf eventuell fehlerhaften oder unvollständigen Daten beruhen, sind, soweit nicht Vorsatz oder grobe Fahrlässigkeit vorliegt, ausgeschlossen.
                </p>
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                  Das Vorgesagte gilt auch für Informationen auf Websites, auf die mittels eines Hyperlinks verwiesen wird. Der Inhalt dieser Websites liegt vollständig außerhalb des Verantwortungsbereiches der Landeskonferenz. Die Websites waren jedoch zur Zeit der Verlinkung frei von illegalen Inhalten. Auf die Gestaltung der gelinkten Websites kann nicht Einfluss genommen werden. Für Schäden, die aus fehlerhaften oder unvollständigen Inhalten auf den mittels Link verwiesenen Websites resultieren, haften der HVNB und die Autoren bzw. Verantwortlichen dieser Website nicht.
Alle aus dieser Website entstehenden Rechtsstreitigkeiten unterliegen ausschließlich deutschem Recht.

                </p>
              </div>
            </div>
        </div>
    </section>
  </Layout>
)

export default Impressum
